class LoadMore {

    constructor() {
        $('.js-load-more').on('click', this.loadMore); // Add on click event
    }

    loadMore() {
        let button = $(this);
        let data = {
            'action': 'loadmore', // Action name referenced in functions.php
            'query': posts_front,
            'page': current_page_front
        }; // Setup the correct data from front-page.php

        $.ajax({
            url: watermill_params.ajaxurl, // watermill_params pulled from wp_register_script in functions.php
            data: data,
            type: 'POST',
            beforeSend: function(xhr) {
                button.text(watermill_params.text_loading + '...');
            },
            success: function(data) {
                if (data) {
                    button.text(watermill_params.text_load);
                    $('.js-posts > .row').append(data); // Print data
                    current_page_front++;

                    if (current_page_front == max_page_front)
                        button.remove();
                } else {
                    button.remove();
                }
            }
        });
    }
}

export { LoadMore };