import Glide from '@glidejs/glide'

if ( $('.js-poker-article-category__slider').length )
{
    $('.js-poker-article-category__slider').each(function() {
        new Glide(this, {
            type: 'carousel',
            perView: 3,
            gap: 30,
            breakpoints: {
                767: {
                  perView: 1,
                },
                991: {
                    perView: 2,
                  },
              },
              
        }).mount();
    });
}