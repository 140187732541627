if( $('.js-poker-header__date__time') && $('.js-poker-header__date__date'))
    startTime();

/*
 * Start the Clock
 *
 * @return void
 */
function startTime() 
{
    const today = new Date();

    // Time
    let h = today.getHours();
    let m = today.getMinutes();
    let s = today.getSeconds();

    m = checkTime(m);
    s = checkTime(s);

    $('.js-poker-header__date__time').text(h + ":" + m);

    // Date
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

    let d = today.getDate();
    let da = weekday[ today.getDay() ];
    let mo = month[ today.getMonth() ];
    let y = today.getFullYear();
    
    $('.js-poker-header__date__date').text(da + ", " + d + nth(d) + " " + mo + " " + y);

    setTimeout( startTime, 1000 );
}

/*
 * Add zero in front of numbers < 10
 *
 * @param i number
 * 
 * @return number
 */
function checkTime(i) 
{
    if ( i < 10 ) 
        i = '0' + i;

    return i;
}

/*
 * Get st, nd, rd, th for dates
 *
 * @param i number
 * 
 * @return string
 */
function nth(d) 
{
    if (d > 3 && d < 21) 
        return 'th';

    switch (d % 10) 
    {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
    }
}
