import Glide from '@glidejs/glide'

if ( $('.js-post-slider-latest__slider').length )
{
  new Glide('.js-post-slider-latest__slider', {
      type: 'carousel',
      perView: 3,
      gap: 30,
      breakpoints: {
          767: {
            perView: 1,
          },
          991: {
              perView: 2,
            },
        },
        
  }).mount();
}