class Helpers {

    wrapTitles() 
    {
        $('h1, h2').each(function() 
        {
            if( $(this).hasClass('js-no-wrap') || $(this).children('.js-no-wrap-parent').length != 0)
                return;

            let count = $(this).text().replace( /[^\w ]/g, '' ).split( /\s+/ ).length; // Word count.
            let regex = '';
            let i;

            for ( i = 0; i < Math.floor( count/2 ); i++ ) // // Round down the word count
            {
                regex += '\\S+\\s'; // Generate regular expression
            }
            
            $(this).html( $(this).html().replace(new RegExp( '(' + regex + ')' ), '<span>$1</span>') ); // Wrap first half of words in span
                
        });
    }
}

export { Helpers };